import React from 'react';
import Header from './HeaderComponent';
import MainBanner from './MainBannerComponent';
import Courses from './CoursesComponent';
import Publicity from './PublicityComponent';
import Footer from './FooterComponent';
//import Loader from './LoaderComponent';
// import { Counter } from '../features/counter/Counter';

const Main = () => {
  return (
    <div>
        {/* <Loader/>  falta implementar su js */}
        <Header/>
        {/* <Counter/> */}
        <MainBanner/>
        <Courses/>
        <Publicity/>
        <Footer/>
    </div>
  )
  

};

export default Main;
