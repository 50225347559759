import React from 'react';

function Modal({ show, onClose, children }) {
    if (!show) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg relative w-full max-w-md mx-4">
                <button 
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" 
                    onClick={onClose}
                >
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
}

export default Modal;
