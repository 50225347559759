import React from "react";

const Publicity = () => {
    return(
        <div class="section cta">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="shop">
            <div class="row">
              <div class="col-lg-12">
                <div class="section-heading">
                  <h6>Material de estudio</h6>
                  <h2>Adquiere el kit de Material de estudio al mejor<em>Precio</em> Para Ti!</h2>
                </div>
                <p>El kit contiene todos los cursos que pide la universidad y tambien practicas para reforzamiento.</p>
                <div class="main-button">
                  {/* <!-- <a href="shop.html">Adquiere ahora</a> --> */}
                  <a href="https://wa.link/q39cy8">Adquiere ahora</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 offset-lg-2 align-self-end">
          <div class="subscribe">
            <div class="row">
              <div class="col-lg-12">
                <div class="section-heading">
                  <h6>GRUPOS DE ESTUDIO</h6>
                  <h2>Unete al <em>Grupo de estudio</em> de Whastapp!</h2>
                </div>
                <div class="main-button" style={{display: 'flex', alignItems: 'center'}}>
                  
                    <input type="text" class="form-control" id="username" aria-describedby="emailHelp" placeholder="Tu nombre"/>
                    {/* <!-- <button type="submit">Unete ahora</button> --> */}
                    
                    <a href="https://chat.whatsapp.com/BmF8saD7kxuLD7KzKhvW3O" >Únete</a>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    );
};

export default Publicity;