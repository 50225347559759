import React from "react";

const Footer = () => {
    return(
        <footer>
    <div class="container">
      <div class="col-lg-12">
        <p>Copyright © 2024 Creditos a LUGX Gaming Company. </p>
      </div>
    </div>
  </footer>
    );
};

export default Footer;