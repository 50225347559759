import React, { useState, useEffect} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import "./paginator.css";

const Paginator = ({data, sizeData,interval = 7000 }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const totalPages = sizeData;
    //console.log("totalPages", totalPages);

    const handleNextPage = () => {
        setCurrentPage((currentPage + 1) % totalPages);
    };

    const handlePreviousPage = () => {
        setCurrentPage((currentPage - 1 + totalPages) % totalPages);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
        }, interval);

        return () => clearInterval(timer);
    }, [totalPages, interval]);
    
    return (
        <div>
            <TransitionGroup>
                <CSSTransition
                    key={currentPage}
                    timeout={300}
                    classNames="fade"
                >
                    <div>
                        <ul>
                            <li key={currentPage}>
                                <a href={data[currentPage].link}><h5><u>{data[currentPage].title}</u></h5></a>
                                <div className="image-container">
                                    {data[currentPage].src && <img src={data[currentPage].src} alt={data[currentPage].title} />}
                                </div>
                            </li>
                        </ul>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <button onClick={handlePreviousPage}>
                    {"< Anterior"}
                </button>
                <span>{currentPage + 1} de {totalPages}</span>
                <button onClick={handleNextPage}>
                    {"Siguiente >"}
                </button>
            </div>
        </div>
    );
};

export default Paginator;   

