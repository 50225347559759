//import logo from './logo.svg';
import './App.css';
import Main from './components/Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.css';
import './assets/css/flex-slider.css';
import './assets/css/fontawesome.css';
import './assets/css/owl.css';
import './assets/css/styles.css';
import './assets/css/templatemo-lugx-gaming.css';
import './index.css';


function App() {
  return (
    <Main/>
  );
}

export default App;
