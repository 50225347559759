import React from "react";

const Header = () => {
    return (
    <header className="header-area header-sticky">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <nav className="main-nav">
                        {/* <!-- ***** Logo Start ***** --> */}
                        <a href="index.html" className="logo">
                            <img src="/assets/images/logosinfondo.png" alt="" style={{width: "158px"}} />
                        </a>
                        {/* <!-- ***** Logo End ***** -->
                        <!-- ***** Menu Start ***** --> */}
                        <ul className="nav">
                        {/* <!-- <li><a href="index.html" className="active">Whastapp</a></li> --> */}
                        <li><a href="https://wa.link/yowlx5" >Quiero material de estudio</a></li>
                        {/* <!-- <li><a href="shop.html">Our Shop</a></li>
                        <li><a href="product-details.html">Product Details</a></li>
                        <li><a href="contact.html">Contact Us</a></li>
                        <li><a href="#">Sign In</a></li> --> */}
                        {/* <button 
                            classNameName="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700" 
                            onClick={handleOpenModal}
                        >
                            Abrir Modal
                        </button> */}
                    </ul>   
                        <div  className='menu-trigger'>
                            <span>Menu</span>
                        </div>
                        
                    </nav>
                </div>
            </div>
        </div>
    </header>
    );
};

export default Header;
