import React from "react";
import Modal from "./ModalComponent";
import { useState } from "react";


const Courses = () => {

    const [showModal, setShowModal] = useState(false);
    const [titleCourse, setTitleCourse] = useState("");

    const handleOpenModal = (name) => {
        setTitleCourse(name);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const CourseCard = ({image,name}) => {
        
        return(
            
            <div class="col-lg-3 col-md-6">
                
              <div  onClick={() => handleOpenModal(name)}>
                <div class="item">
                  <div class="image">
                    <img src={image} alt="" style={{maxWidth: "44px"}}/>
                  </div>
                  <h4>{name}</h4>
                </div>
              </div>
            </div>
            
        );
    }

    return(
        <div class="features">
            <div class="container">
                <div class="row">
                    <CourseCard image={"/assets/images/featured-01.png"} name={"Raz. Matemático"}/>
                    <CourseCard image={"/assets/images/featured-02.png"} name={"Álgebra"}/>
                    <CourseCard image={"/assets/images/featured-03.png"} name={"Trigonometría"}/>
                    <CourseCard image={"/assets/images/featured-04.png"} name={"Raz. Verbal"}/>
                    <CourseCard image={"/assets/images/featured-01.png"} name={"Física"}/>
                    <CourseCard image={"/assets/images/featured-02.png"} name={"Química"}/>
                    <CourseCard image={"/assets/images/featured-03.png"} name={"Historia"}/>
                    <CourseCard image={"/assets/images/featured-04.png"} name={"Psicología"}/>
                    
                </div>

            </div>
            
            <Modal show={showModal} onClose={handleCloseModal}>
                <h2 className="text-2xl font-bold">{titleCourse}</h2>
                <p className="mt-4">Ejercicios propuestos</p>
            </Modal>
        </div>
    );
};

export default Courses